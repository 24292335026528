import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const Hero = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(Hero, { mix, mods });

    return (
        <section
            className={ className  + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </section>
    );
};

Hero.displayName = 'Hero';

Hero.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Hero.defaultProps = {
    mix: {},
    mods: {}
};

export default Hero;
