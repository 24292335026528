import Info from '@components/Info';
import Section from '@components/Section';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';
import Heading from '@components/UI/Typography/Heading';
import Icon from '@components/UI/Icon';
import TimeCounter from '@components/UI/TimeCounter';

import css from '@utils/css';

import React from 'react';


const FeedbackBanner = () => (
    <Section
        mods={ { home: true } }
    >
        <Container>
            <Info
                mods={ { center: true } }
                className={ css('relative flex flex-col gap-4 justify-center text-center p-8 md:p-12 xl:p-16') }
            >
                <div
                    className='absolute left-0 top-0 h-full w-full overflow-hidden -z-[1] rounded-16p'
                >
                    <div
                        className='radials radials_dark w-[1716px] h-[1716px]'
                    >
                    </div>
                    <div
                        className='radials radials_dark w-[960px] h-[960px]'
                    >
                    </div>
                    <div
                        className='radials radials_dark w-[600px] h-[600px]'
                    >
                    </div>
                </div>
                <h2
                    className='heading heading_white h3'
                >
                    Have questions?<br/><span className={'text-violet-700'}>Write to us or</span>
                </h2>
                <div className={ css('flex flex-col gap-4 items-start justify-center') }>
                    <Button
                        tag='a'
                        type='link'
                        href='/demo'
                        variant='primary'
                        size='lg'
                        mods={{'no-spacing': true}}
                        className={'self-center'}
                    >
                        Book a demo
                    </Button>
                </div>
            </Info>
        </Container>
    </Section>
);

export default FeedbackBanner;
