import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const CardBody = ({ textTop, title, text, mods, mix }) => {
    const { className } = useMods(CardBody, { mix, mods });

    return (
        <div
            className={ className }
        >
            { textTop }
            { title }
            { text }
        </div>
    );
};

CardBody.displayName = 'Card__Body';

CardBody.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object,
    text: PropTypes.object,
    textTop: PropTypes.object,
    title: PropTypes.object
};

CardBody.defaultProps = {
    mix: {},
    mods: {}
};

export default CardBody;
