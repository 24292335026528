import Grid from '@components/Grid';
import Section from '@components/Section';
import Container from '@components/UI/Container';
import Paragraph from '@components/UI/Typography/Paragraph';

import React from 'react';

import HomeData from './data.js';

const Numbers = () => {
    const { numbers} = HomeData;

    return (
        <Section
            mods={ { home: true } }
        >
            <Container>
                <Grid
                    xs={ 1 }
                    md={ 2 }
                    lg={ 4 }
                    gx={ 20 }
                    gy={ 8 }
                    mods={ { divide:true } }
                >
                    {numbers.map(item => (
                        <div
                            key={ item.id }
                            className='card card_no-spacing card_no-spacing-y'
                        >
                            <div
                                className='card__body'
                            >
                                <span
                                    className='heading h2'
                                >
                                    {item.value}
                                </span>
                                <Paragraph size='lg' color='neutral_700'>
                                    { item.text }
                                </Paragraph>
                            </div>
                        </div>
                    ))}
                </Grid>
            </Container>
        </Section>
    );
};

export default Numbers;
