import Card from '@components/Card';
import Grid from '@components/Grid';
import Section, { SectionHeadline } from '@components/Section';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';
import Link from '@components/UI/Link';
import Picture from '@components/UI/Picture';
import Heading from '@components/UI/Typography/Heading';
import Paragraph from '@components/UI/Typography/Paragraph';

import React from 'react';

import HomeData from './data.js';

const Cards = () => {
    const { useCases } = HomeData;

    return (
        <Section
            mods={ { home: true } }
        >
            <Container>
                <SectionHeadline
                    mods={ { center: true } }
                >
                    <Heading
                        tag='h2'
                    >
                        <span className={'text-violet-700'}>Effective software</span> for all types of teams
                    </Heading>
                </SectionHeadline>

                <Grid
                    xs={ 1 }
                    md={ 3 }
                    gx={ 6 }
                    gy={ 10 }
                >
                    { useCases.map(item => (
                        <Card
                            key={ item.id }
                            mods={ {
                                center: true,
                                shadow: true,
                                'features': true
                            } }
                            image={
                                <Picture
                                    image={item.image}
                                    alt={item.alt}
                                    sizes='(min-width: 1024px) 25vw, (min-width: 768px) 30vw, (min-width: 425px) 50vw, 50vw'
                                    props={{ width: 240 }}
                                    mods={{ centered: true }}
                                    className='p-10 pb-0'
                                />
                            }
                            body={ {
                                title: (

                                    <h3
                                        className='heading h6 mt-6'
                                    >
                                        { item.title }
                                    </h3>
                                ),
                                text: (
                                    <Link
                                        href={ item.url }
                                        mods={ {
                                            violet: true,
                                            semibold: true
                                        } }
                                        linkProps={ { 'title': item.alt } }
                                    >
                                        Learn More
                                    </Link>
                                )
                            } }
                        />
                    )) }
                </Grid>
            </Container>
        </Section>
    );
};

export default Cards;
