import Grid from '@components/Grid';
import Info from '@components/Info';
import Section from '@components/Section';
import Container from '@components/UI/Container';
import Icon from '@components/UI/Icon';
import Link from '@components/UI/Link';
import Picture from '@components/UI/Picture';
import Paragraph from '@components/UI/Typography/Paragraph';
import HomeData from '@screens/Home/data';

import css from '@utils/css';
import RenderIf from '@utils/renderIf';
import Image from 'next/future/image';

import React from 'react';

const Cards = () => {
    const { cards } = HomeData

    return (

        <Grid
            xs={ 1 }
            md={ 2 }
            gx={ 6 }
            gy={ 6 }
            className={ css('mt-6 lg:mt-12') }
        >
        { cards.map(item => (
                <div
                    key={ item.id }
                    className={ css('card card_hover card_text-block card_shadow text-left',
                        item?.widthProp === 'full' ? 'md:col-span-2' : '',
                        item.hoverColor ? `card_hover_${item.hoverColor}` : ''
                    ) }
                >
                    <div className='flex flex-col gap-2 p-6 sm:px-10 md:px-6 lg:p-8 xl:px-12'>
                        <h2 className='card__heading heading h5'>
                            { item.title }
                        </h2>
                        <Paragraph size='lg' color='neutral_700'>
                            { item.desc }
                        </Paragraph>
                        <Link
                            href={ item.url }
                            mods={ {
                                violet: true,
                                semibold: true,
                                stretched: true
                            } }
                            linkProps={ { 'title': item.title } }
                        >
                            Learn More
                            <Icon
                                name='chevron-right'
                                size={ 10 }
                                color='violet-700'
                                className='link__icon'
                            />
                        </Link>
                    </div>

                    { item.image && (<Picture
                        image={ item.image }
                        alt={ item.alt }
                        sizes={ item?.widthProp === 'full' ?
                            '100vw'
                            : '(min-width: 1024px) 50vw, 100vw'}
                        className={ item.imageMobile ? 'max-md:hidden' : '' }
                    >
                        <RenderIf
                            isTrue={ Boolean(item?.imageAdditional && item.id===3) }
                        >
                            <div className={ css('max-md:hidden absolute -right-[5.6%] -top-[20%] w-[34%]') }>
                                <Image
                                    src={ item.imageAdditional }
                                    alt={ item.altMobile }
                                />
                            </div>
                        </RenderIf>

                    </Picture>) }

                    { item.imageMobile && (<Picture
                        image={ item.imageMobile }
                        alt={ item.altMobile }
                        className='hidden max-md:block'
                        sizes={ '50vw' }
                    />) }
                </div>
            )) }
        </Grid>
    )
}

const HomeUtp = () => (
    <Section
        mods={{ home: true }}
    >
        <Container>
            <Info
                mods={ { center: true } }
                className={ css('max-w-[996px] mx-auto') }
            >
                <p className={ css('heading h2') }>
                    Why choose <span className={'text-violet-700'}>Traqq</span>
                </p>

                <Paragraph
                    tag='p'
                    size='lg'
                    className={ css('!leading-32p') }
                >
                    Traqq is the <span className={'text-violet-700'}>most intuitive</span> time management software for workforce optimization
                </Paragraph>
            </Info>
            <Cards />
        </Container>
    </Section>
);

export default HomeUtp;
