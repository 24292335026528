import { OrganizationJsonLd, ProductJsonLd } from 'next-seo';

export default () => {

    const siteUrl = process.env.NEXT_PUBLIC_APP_URL

    return (
        <>
            <OrganizationJsonLd
                type="Organization"
                logo={ `${siteUrl}/logo180.png` }
                legalName="Traqq Pty Ltd."
                name="Traqq"
                brand={ {
                    "@type":"Brand",
                    "name": "Traqq"
                } }
                foundingDate="2019"
                address={ {
                    streetAddress: 'Level 10, 88 Phillip Street',
                    addressLocality: 'Sydney NSW 2000',
                    addressRegion: 'NSW',
                    postalCode: '2000',
                    addressCountry: 'AU',
                } }
                contactPoint={ [
                    {
                        contactType: 'customer support',
                        email: 'support@traqq.com',
                    },
                ] }
                sameAs={ [
                    'https://www.facebook.com/profile.php?id=100091955341253',
                    'https://x.com/traqqapp?lang=en',
                    'https://www.linkedin.com/company/traqq-time-tracking-software/',
                    'https://www.youtube.com/@traqqtimetracker',
                    'https://www.capterra.com/p/230726/Traqq/',
                    'https://www.crunchbase.com/organization/traqq',
                    'https://www.getapp.com/project-management-planning-software/a/traqq/'
                ] }
                url={ siteUrl }
            />
            <ProductJsonLd
                type="WebApplication"
                productName="Traqq"
                applicationCategory="BusinessApplication"
                datePublished="2019-01-15T23:21:55.000Z"
                operatingSystem="Web platform, Windows, Mac OS, Linux"
                description="Traqq is an automated time tracker and employee monitoring software"
                url={ siteUrl }
                images={ `${siteUrl}/traqq-time-tracker-features.png` }
                offers={ [
                    {
                        price: '0',
                        priceCurrency: 'USD'
                    }
                ] }
                featureList={ siteUrl }
                screenshot={ `${siteUrl}/time-tracker.png` }
                aggregateRating={ {
                    ratingValue: '4.8',
                    ratingCount: '103',
                } }
                reviews={ [
                    {
                        type: 'Person',
                        author: 'Sarvarbek Yuldashev',
                        datePublished: '2023-05-24',
                        description:
                            'Traqq\'s customizations and easy UI have been a valuable addition to our workflow. The anti-cheating algorithm ensures our team members are accountable and focused on their tasks.',
                        name: 'In our logistics company',
                        reviewRating: {
                            bestRating: '5',
                            ratingValue: '5',
                            worstRating: '1',
                        },
                    },
                    {
                        type: 'Person',
                        author: 'Lauren Hinchliff',
                        datePublished: '2023-06-12',
                        description:
                            'Traqq provides detailed reports and diverse analytics. It\'s helpful to see data on user productivity and consolidated team productivity.',
                        name: 'Recommended for diverse analytics',
                        reviewRating: {
                            bestRating: '5',
                            ratingValue: '4',
                            worstRating: '1',
                        },
                    },
                ] }
                softwareHelp={ `${siteUrl}/support` }
                author={ {
                    '@type': 'Organization',
                    name: 'Traqq Pty Ltd.',
                    url: `${siteUrl}`,
                    logo: {
                        '@type': 'ImageObject',
                        url: `${siteUrl}/logo180.png`,
                        width: '180px',
                        height: '180px'
                    }
                } }
                video={ {
                    '@type': 'VideoObject',
                    caption: 'Traqq: Time Tracker for on-site and remote teams',
                    thumbnailUrl: 'https://i.ytimg.com/vi/WvHO782ebpw/maxresdefault.jpg',
                    contentUrl: 'https://www.youtube.com/watch?v=WvHO782ebpw',
                    name: 'Traqq Time Tracker Tour',
                    description: 'A 2-minute video showing how you can track time and productivity with Traqq software',
                    uploadDate: '2023-06-12T00:00:00.000Z'
                } }
            />
        </>
    )
}
