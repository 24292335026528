import useMods from '@hooks/useMods';
import Logo from '@components/UI/Logo';

import PropTypes from 'prop-types';
import React from 'react';

const TimeCounter = ({ mods, className: classNameTail }) => {
    const { className } = useMods(TimeCounter, { mods });

    return (
        <div className={ className + (classNameTail ? ' ' + classNameTail : '') }>
            <div
                className={ 'bg-violet-200 rounded-full p-2.5' }
            >
                <Logo
                    width={ 32 }
                />
            </div>
            <div
                className={ 'flex items-center bg-violet-200 rounded-full' }
            >
                <span
                    className={ 'mobile-heading-3 text-neutral-900 px-6' }
                >
                    00:00:00
                </span>
            </div>
        </div>
    );
};

TimeCounter.displayName = 'TimeCounter';

TimeCounter.propTypes = {
    mods: PropTypes.object
};

TimeCounter.defaultProps = {
    mods: {}
};

export default TimeCounter;
