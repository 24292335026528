import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const Reviews = ({ children, mods, mix, className: classNameTail }) => {
    const { className } = useMods(Reviews, { mix, mods });

    return (
        <section
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { children }
        </section>
    );
};

Reviews.displayName = 'Reviews';

Reviews.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

Reviews.defaultProps = {
    mix: {},
    mods: {}
};

export default Reviews;
