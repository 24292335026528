import Info from '@components/Info';
import Section from '@components/Section';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';
import Icon from '@components/UI/Icon';
import TimeCounter from '@components/UI/TimeCounter';

import css from '@utils/css';

import React from 'react';


const CTABanner = () => (
    <Section
        mods={ { home: true } }
    >
        <Container>
            <Info
                mods={ { center: true } }
                className={ css('relative flex flex-col gap-4 justify-center text-center p-8 md:p-12 xl:p-16') }
            >
                <div
                    className='absolute left-0 top-0 bg-white h-full w-full overflow-hidden -z-[1] rounded-16p'
                >
                    <div
                        className='radials radials_gradient_third w-[1716px] h-[1716px]'
                    >
                    </div>
                    <div
                        className='radials w-[960px] h-[960px]'
                    >
                    </div>
                    <div
                        className='radials radials_gradient_first w-[600px] h-[600px]'
                    >
                    </div>
                </div>
                <div className='flex justify-center'>
                    <TimeCounter />
                </div>
                <h2
                    className='heading h3'
                >
                    Get started with<br/>
                    <span className={'text-violet-700'}>Traqq time tracking app</span>
                </h2>
                <div className={ css('flex flex-col gap-4 justify-center max-sm:w-full') }>
                    <Button
                        tag='a'
                        type='link'
                        href={`${process.env.NEXT_PUBLIC_APP_TRAQQ_URL}/signup`}
                        variant='primary'
                        size='lg'
                        mods={{'no-spacing': true}}
                        className={'max-sm:w-full self-center'}
                    >
                        Try now
                    </Button>
                    <span className={ css('flex items-center justify-center text-neutral-600 gap-2') }>
                        <Icon
                            name='card' color='#7F818F' size={ 24 }
                        />
                        No credit card required
                    </span>
                </div>
            </Info>
        </Container>
    </Section>
);

export default CTABanner;
