import Navigation from '@layouts/Navigation/Traqq';
import Utp from '@screens/Home/Utp';

import React from 'react';

import JsonLd from './JsonLd';
import Hero from './Hero';
import Reviews from './Reviews';
import Blockquote from './Blockquote';
import CTABanner from './CTABanner';
import Cards from './Cards';
import Opinions from './Opinions';
import Numbers from './Numbers';
import Badges from './Badges';
import FeedbackBanner from './FeedbackBanner';

const Home = () => {

    return (
        <>
            <JsonLd />
            <Navigation />
            <Hero />
            <Reviews />
            <Utp />
            <Blockquote />
            <CTABanner />
            <Cards />
            <Opinions />
            <Numbers />
            <Badges />
            <FeedbackBanner />
        </>
    );
};

export default Home;
