import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

import Body from './CardBody';
import Footer from './CardFooter';

const Card = ({ image, imageBottom, body, footer, mods, mix, className: classNameTail  }) => {
    const { className } = useMods(Card, { mix, mods: { ...mods } });

    return (
        <div
            className={ className + (classNameTail ? ' ' + classNameTail : '') }
        >
            { image }

            <Body
                title={ body.title }
                textTop={ body.textTop }
                text={ body.text }
            />

            { imageBottom }

            {footer && <Footer>
                { footer }
            </Footer>}
        </div>
    );
};

Card.displayName = 'Card';

Card.propTypes = {
    body: PropTypes.object,
    footer: PropTypes.node,
    image: PropTypes.node,
    imageBottom: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object,
    title: PropTypes.node
};

Card.defaultProps = {
    mix: {},
    mods: {}
};

export default Card;
