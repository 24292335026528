import Layout from '@layouts/Layout'
import Home from '@screens/Home'

import React, { memo } from 'react'

const title = 'Traqq Time Tracker: Software for Teams and Individuals'
const description = 'Time tracking software for teams of any size. FREE 21-day trial. A simple and accurate time tracker to monitor activity and work time worldwide.'

const HomePage = () => (
    <Layout
        title={ title }
        description={ description }
        useTitleTemplate=''
        additionalMetaTags={[{
            itemProp: 'name',
            content: 'Employee Time Tracking Software - Traqq',
            keyOverride: 'prop-name',
        }, {
            itemProp: 'description',
            content: 'Employee time tracking software and employee monitoring software for on-site and remote teams.',
            keyOverride: 'prop-description',
        }, {
            itemProp: 'image',
            content: `${process.env.NEXT_PUBLIC_APP_URL}/traqq-time-tracker-features.png`,
            keyOverride: 'prop-image',
        }]}
    >
        <Home/>
    </Layout>
)

export default memo(HomePage)
