import Section, { SectionHeadline } from '@components/Section';
import Container from '@components/UI/Container';
import Icon from '@components/UI/Icon';
import Heading from '@components/UI/Typography/Heading';
import Paragraph from '@components/UI/Typography/Paragraph';
import useEventHandler from '@hooks/useEventHandler';
import css from '@utils/css';

import React, { useEffect, useState } from 'react';

import HomeData from './data.js';

const ReviewCarousel = ({ data, className }) => {

    const getViewCount = () => window.innerWidth >= 768 ? 2 : 1;

    const [currentReviews, setCurrentReviews] = useState([data[0]]);

    const onResize = () => {
        if (getViewCount() === 2 && currentReviews.length === 1) {
            setCurrentReviews([
                currentReviews[0],
                data.find(val => val.id === currentReviews[0].id + 1) ?? data.find(val => val.id === 1)
            ]);
        } else if (getViewCount() === 1 && currentReviews.length === 2) {
            setCurrentReviews([currentReviews[0]]);
        }
    };

    useEffect(() => {
        if (getViewCount() === 2) {
            setCurrentReviews([data[0], data[1]]);
        }
    }, []);

    useEventHandler('resize', onResize);

    const changeReview = (action) => {
        if (action === 'next') {
            const getReview = (reviewId) => {
                const nextReviewId = reviewId + 1;
                return data.find(elem => elem.id === nextReviewId) ?? data.find(elem => elem.id === 1);
            };

            if (getViewCount() === 2) {
                const next = getReview(currentReviews[0].id + 1);
                const next2 = getReview(next.id);
                setCurrentReviews([next, next2]);
            } else {
                const next = getReview(currentReviews[0].id);
                setCurrentReviews([next]);
            }

        } else if (action === 'prev') {
            const getReview = (reviewId) => {
                const nextReviewId = reviewId - 1;
                return data.find(elem => elem.id === nextReviewId) ?? data.at(-1);
            };

            if (getViewCount() === 2) {
                const next = getReview(currentReviews[0].id);
                const next2 = getReview(next.id);

                setCurrentReviews([next2, next]);
            } else {
                const next = getReview(currentReviews[0].id);

                setCurrentReviews([next]);
            }
        }
    };

    return <>
        <div className={ css('carousel relative') }>
            <div className='grid gap-x-6 col-span-2 480:col-start-2 768:grid-cols-2'>
                { currentReviews.map((review, index) => {

                    return (
                        <div
                            key={ index }
                            className={ 'card card_shadow items-stretch min-h-[236px] lg:min-h-[248px]' }
                        >
                            <div className={ 'flex flex-col justify-between gap-2 min-h-full p-6 sm:px-10 md:px-6 lg:p-8 xl:px-12' }>
                                <Paragraph size='lg'>
                                    { review.body }
                                </Paragraph>
                                <footer>
                                    <div
                                        className={ 'text-neutral-600' }
                                    >
                                        { `${review.author} — ${review.position}` }
                                    </div>
                                    <div className={ css('flex items-center mt-2') }>
                                        { [...Array(5).keys()].map(i => <Icon key={ i } name='fivePointedStar' color='yellow-700' size={ 20 }/>) }
                                    </div>
                                </footer>
                            </div>
                        </div>
                    );
                }) }
            </div>
            <div className='absolute -left-6 1024:-left-8 top-1/2 -translate-y-1/2'>
                <button className={ css('bg-[rgba(255,255,255,0.8)] flex items-center justify-center w-12 h-12 rounded-full button_shadow 1024:(w-16 h-16)') }
                        onClick={ () => changeReview('prev') }>
                    <Icon className='rotate-180' name='chevron-right' size={ 20 } color='violet-700'/>
                </button>
            </div>
            <div className='absolute -right-6 1024:-right-8 top-1/2 -translate-y-1/2 shadow-trq'>
                <button className={ css('bg-[rgba(255,255,255,0.8)] flex items-center justify-center w-12 h-12 rounded-full button_shadow 1024:(w-16 h-16)') }
                        onClick={ () => changeReview('next') }>
                    <Icon name='chevron-right' size={ 20 } color='violet-700'/>
                </button>
            </div>
        </div>
    </>;
};

const HomeOpinions = () => {
    const { opinions } = HomeData;

    return (
        <Section
            mods={ { home: true } }
        >
            <Container>

                <SectionHeadline
                    mods={ { center: true } }
                >
                    <Heading
                        tag='h2'
                        className='max-w-[500px] mx-auto lg:max-w-[720px]'
                    >
                        <span className={'text-violet-700'}>Join the community</span> of grateful Traqq users
                    </Heading>
                </SectionHeadline>

                <ReviewCarousel className='mb-8 768:mb-12' data={ opinions }/>

            </Container>
        </Section>
    );
};

export default HomeOpinions;
