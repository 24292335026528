import Hero from '@components/Hero';
import Info from '@components/Info';
import Button from '@components/UI/Button';
import Container from '@components/UI/Container';
import Icon from '@components/UI/Icon';
import Heading from '@components/UI/Typography/Heading';
import Paragraph from '@components/UI/Typography/Paragraph';

import css from '@utils/css';

import React from 'react';

const HomeHero = () => (
    <Hero
        mods={ { home:true } }
    >
        <Container>
            <Info
                mods={ { center: true } }
                className={ css('max-w-[996px] mx-auto') }
            >
                <Heading
                    tag='h1'
                >
                    <span className={'text-violet-700'}>Time tracker</span> for project teams to bring your business to <span className={'text-violet-700'}>the next level</span>
                </Heading>

                <Paragraph
                    tag='p'
                    size='lg'
                    className={ css('!leading-32p max-w-[820px]') }
                >
                    Traqq is a powerful timesheet app and time-tracking software for remote and on-site teams worldwide. Track work hours, generate reports, and say goodbye to billing and payroll nightmares
                </Paragraph>

                <div className={ css('flex items-center mt-2') }>
                    { [...Array(5).keys()].map(i => <Icon key={ i } name='fivePointedStar' color='yellow-700' size={ 24 }/>) }
                    <div className={ css('text-neutral-600 ml-3') }>
                        { `${process.env.NEXT_PUBLIC_REVIEW_RATING} (${process.env.NEXT_PUBLIC_REVIEW_QTY} reviews)` }
                    </div>
                </div>

                <div className={ css('flex flex-col gap-4 justify-center mt-2') }>
                    <Button
                        tag='a'
                        type='link'
                        href={ `${process.env.NEXT_PUBLIC_APP_TRAQQ_URL}/signup` }
                        variant='primary'
                        size='lg'
                        mods = { { 'no-spacing': true } }
                    >
                        Start tracking time for free
                    </Button>
                    <span className={ css('flex items-center justify-center text-neutral-500 gap-2') }>
                        <Icon
                            name='card'
                            color='neutral-500'
                            size={ 24 }
                        />
                        No credit card required
                    </span>
                </div>
            </Info>
        </Container>
    </Hero>
);

export default HomeHero;
