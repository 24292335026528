import { Reviews, ReviewsItem, ReviewsList } from '@components/Reviews';
import Container from '@components/UI/Container';
import Link from '@components/UI/Link';

import React from 'react';

import HomeData from './data.js';

const HomeReviews = () => {
    const { reviews } = HomeData;

    return (
        <Reviews
            mods={ { theme: 'home' } }
        >
                <ReviewsList>
                    { reviews.map(review => (
                        <ReviewsItem
                            key={ review.id }
                        >
                            { /* eslint-disable-next-line @next/next/no-img-element */ }
                            <Link href={ review.link } target='_blank'>
                                <img
                                    src={ review.src }
                                    alt={ review.alt }
                                    style={ review.style }
                                />
                            </Link>
                        </ReviewsItem>
                    )) }
                </ReviewsList>
        </Reviews>
    );
};

export default HomeReviews;
