import useMods from '@hooks/useMods';

import PropTypes from 'prop-types';
import React from 'react';

const ReviewsList = ({ children, mods, mix }) => {
    const { className } = useMods(ReviewsList, { mix, mods });

    return (
        <ul
            className={ className }
        >
            { children }
        </ul>
    );
};

ReviewsList.displayName = 'Reviews-List';

ReviewsList.propTypes = {
    children: PropTypes.node,
    mix: PropTypes.object,
    mods: PropTypes.object
};

ReviewsList.defaultProps = {
    mix: {},
    mods: {}
};

export default ReviewsList;
